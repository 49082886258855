#about{
	.page-ttl{
		background-image:url('../img/page-ttl/about_ttl.jpg');
	}

	.about-content{
		padding:100px 0 170px;
		@include bpSp{
			padding:50px 0 85px;
		}

		p{
			font-size:1.8rem;
			text-align: center;
			@include bpSp{
				font-size:1.5rem;
				line-height: 1.8;
			}
		}

		.short-infor{
			font-size:1.8rem;
			margin-top: 50px;
			margin-bottom: 75px;
			@include bpSp{
				margin-top: 25px;
				margin-bottom: 35px;
				font-size:1.4rem;
			}
			dl{
				display:flex;
				border-bottom:1px solid $color1;
				padding-left:270px;
				@include breakpoint(1030px){
					padding-left:27%;
				}
				@include bpSp{
					padding-left:15px;
				}
				&:last-child{
					border-bottom: none;
				}
			}
			dt, dd{
				padding:22px 0;
				@include bpSp{
					padding:10px 0;
				}
			}
			
			dt{
				min-width:100px;
				margin-right:70px;
				@include bpSp{
					margin-right: 15px;
				}
			}
		}
		
		.logo-about{
			margin-bottom: 80px;
			@include bpSp{
				width:150px;
				margin:0 auto 20px;
			}
		}
		.note{
			border:1px solid $color1;
		    padding: 5px;
		    margin-top: 20px;
		    @include bpSp{
		    	padding:2px 5px;
		    }
			ul{
				display:flex;
				justify-content: center;
				flex-wrap:wrap;
				li{
					font-size:1.6rem;
					margin-right: 10px;
					@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
						display:flex;
						align-items: center;
						span{
							font-size:3.5rem;
							line-height: 1;
						}
					}
					@include bpSp{
						font-size:1.3rem;
					}
					&:last-child{
						margin-right:0px;
					}
					.dot-color{
						&1{
							color:#E7380D;
						}
						&2{
							color:#6FB92C;
						}
						&3{
							color:#B28146;
						}
						&4{
							color:#036EB7;
						}
					}
				}
			}
		}
	}
}