@charset "UTF-8";

.container{
	width:1030px;
	margin:0 auto;
	text-align: left;
	padding:0 15px;
	@include breakpoint(1030px){
		width:100%;
	}
	&.wide{
		width:1130px;
		@include breakpoint(1130px){
			width:100%;
		}
	}
}
.l-header{
	position:relative;
	.logo{
		position: absolute;
		top: 25px;
		left: 30px;
		z-index: 999;
		@include breakpoint(1030px){
			position: fixed;
			width:180px;
		}
		@include bpSp{
			width:140px;
			left:15px;
			top:10px;
		}
	}
	.spBtn{
		position:fixed;
		top:40px;
		right:30px;
		display:none;
		cursor: pointer;
		z-index:999;
		@include breakpoint(1030px){
			display:block;
			width:50px;
			height:43px;
		}
		@include bpSp{
			width:35px;
			height:27px;
			top:30px;
			right:15px;
		}
		span{
			background-color: #fff;
			width:100%;
			height:3px;
			margin: auto;
			left: 0; right: 0;
			position:absolute;
			display: inline-block;
			transition: 0.3s;
			&:nth-child(1){ top: 0px;}
			&:nth-child(2){	top: 20px;}
			&:nth-child(3){	top: 40px; }
			@include bpSp{
				&:nth-child(1){ top: 0px;}
				&:nth-child(2){	top: 12px;}
				&:nth-child(3){	top: 24px; }
			}
		}
		&.active{
			span{
				&:nth-child(1){	top: 20px;	transform: rotate(135deg); }
				&:nth-child(2){	opacity: 0; }
				&:nth-child(3){	top: 20px; transform: rotate(-135deg);	}
				@include bpSp{
					&:nth-child(1){	top: 12px;	transform: rotate(135deg); }
					&:nth-child(3){	top: 12px; transform: rotate(-135deg);	}
				}
			}
		}
	}
}

.l-main{
	.nav-wr{
		display:flex;
		justify-content: space-between;
		align-items: center;
		margin:25px 0;
		@media (min-width: 1031px) {
			display: flex !important;
		}
		@include breakpoint(1030px){
			display:none;
			position:fixed;
			background:#fff;
			top:0;
			left:0;
			width:100%;
			margin: 0 0;
			z-index: 100;
			padding:120px 15px;
			background:$color1;
			color:#fff;
			overflow-y: scroll;
			height:100%;
		}
		@include bpSp{
			padding:80px 15px;
		}
		li{
			@include breakpoint(1030px){
				border-bottom:1px solid #fff;
			}
			a{
				display:block;
				position: relative;
			    padding: 5px 20px;
			    &.top{
			    	.ja{
			    		top:50%!important;
			    	}
				    &:before{
				    	content:'';
				    	display:block;
				    	width:15px;
				    	height:1px;
				    	background:$color1;
				    	position:absolute;
				    	bottom:0px;
				    	left:0;
				    	right:0;
				    	margin:0 auto;
				    	@include breakpoint(1030px){
				    		display:none;
				    	}
				    }
				    &:after{
				    	content:'';
				    	display:block;
				    	border-bottom: 1px solid $color1;
				    	@include breakpoint(1030px){
				    		display:none;
				    	}
				    }
			    }
				.ja{    
					position:absolute;
					@include center;
					text-align: center;
				    width: 100%;
				    text-align: center;
				    display: inline-block;
				    @include font(cz);
				    opacity: 0;
				    transition:opacity .2s ease;
				    font-size:1.9rem;
				    font-weight: bold;
				    @include breakpoint(1030px){
				    	position:static;
				    	opacity:1;
				    	text-align: left;
				    	transform:none;
				    }
				    @include bpSp{
				    	font-size:1.4rem;
				    }
				}
				.en{
					@include font(cz);
					font-size:1.9rem;
					@include breakpoint(1030px){
						opacity: 1!important;
					}
					@include bpSp{
						font-size:1.5rem;
					}
				}
				&.green{
					background:$color1;
					color:#fff;
					@include breakpoint(1030px){
						background:#fff;
						color:$color1;
						text-align: center;
						margin: 10px 0;
						.ja{
							text-align: center;
						}
					}
					.ja{
						&:before, &:after{
							display:none;
						}
					}
				}
				&.in-active{
					pointer-events: none;
					@include breakpoint(1030px){
						display:none;
					}
					.en{
						opacity:0.3!important;
					}
					.ja{
						opacity:0!important;
					}
				}
				&:hover{
					.ja{
						opacity:1;
					}
					.en{
						opacity:0;
					}
				}
			}
		}
	}
}

.l-footer{
	.toTop{
		position: fixed;
		right:30px;
		bottom:20px;
		display:none;
		z-index: 999;
		@include bpSp{
			width:30px;
			right:15px;
		}
	}
	.f-infor{
		background:$color1;
		color:#fff;
		display:flex;
		align-items: center;
		padding:30px;
		@include bpSp{
			flex-direction: column;
			align-items: start;
			padding:10px 15px 15px;
		}
		.f-logo{
			margin-right: 35px;
			width:210px;
			@include bpSp{
				margin-right: 0px;
				width:135px;
			}
		}
		.f-txt{
			line-height: 1.7;
			font-size:1.7rem;
		    margin-top: 20px;
		    @include bpSp{
		    	font-size:1.4rem;
		    	line-height: 1.5;
		    	margin-top: 7px;
		    }
			.addr{
				@include bpSp{
					display:flex;
				}
				&:before{
					content: "\f3c5";
					color: #fff;
					font-size: 2rem;
					display: inline-block;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					line-height: 1;
					margin-right: 3px;
				}
			}
		}
	}
	.copyright{
		background:#000;
		color:#fff;
		font-size:1.2rem;
		letter-spacing: 0.05em;
		padding:0 30px;
		@include bpSp{
			padding:5px 15px;
			line-height: 1.4;
		}
	}
}