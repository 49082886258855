.listStyle01{
	display:flex;
	flex-wrap:wrap;
	@include breakpoint(1030px){
		justify-content: space-between;
	}
	li{
		margin-right: 12px;
		width:32.5%;
		margin-bottom: 65px;
		@include breakpoint(1030px){
			margin-right: 0px;
			width:48.5%;
		}
		@include bpSp{
			width:100%;
			margin-bottom: 10px;
		}
		a{
			display:block;
		}
		&:nth-child(3n){
			margin-right: 0px;
		}
		.image{
			height:325px;
			@include bpSp{
				height:100%;
				position: relative;
				padding-bottom: calc(50% + 20px);
			}
			img{
				width:100%;
				height: 100%;
				object-fit: cover;
				@include bpSp{
					position:absolute;
					top:0;
					left:0;
				}
			}
		}
		.time{
			font-size:1.4rem;
			text-align: center;
			line-height: 1;
			position:relative;
			margin:10px 0 15px;
			@include bpSp{
				font-size:1.2rem;
				margin:5px 0 15px;
			}
			&:before{
				content:'';
				display:block;
				position:absolute;
				bottom:-8px;
				left:0;
				right:0;
				height:1px;
				width:170px;
				background-color:currentColor;
				margin:0 auto;
			}
			&:after{
				content:'';
				position:absolute;
				bottom:-11px;
				left:0;
				right:0;
				height:1px;
				width:70px;
				background-color:currentColor;
				margin:0 auto;
			}
		}
		.desc{
			font-size:2.1rem;
			line-height: 1.5;
			text-align: center;
			@include bpSp{
				font-size:1.4rem;
			}
		}
	}
}