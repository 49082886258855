#report{
	.page-ttl{
		background-image:url('../img/page-ttl/report_ttl.jpg');
	}
	.report-list{
		padding:70px 0 130px;
		@include bpSp{
			padding:35px 0 65px;
		}
	}
	.c-pagination{
		margin-top:30px;
	}
}

#report-single{
	.page-ttl{
		background-image:url('../img/page-ttl/report_ttl.jpg');
	}
	.report-single{
		padding:70px 0 130px;
		@include bpSp{
			padding:35px 0 65px;
		}
	}
	.report-content{
		.small-top{
			display:flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			@include bpSp{
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 5px;
			}
			.date{
			    margin-right: 10px;
				color:#000;
				line-height: 1.4;
				@include bpSp{
					font-size:1.3rem;
				}
			}

			.cat{
				span{
					display:inline-block;
					font-size:1.3rem;
					color:#fff;
					background-color:#036EB7;
					min-width: 100px;
					text-align: center;
					padding:2px 5px;
					border-radius: 5px;
					line-height: 1.4;
					@include bpSp{
						font-size:1.2rem;
					}
				}
			}
		}

		.single-ttl{
			font-size:3.6rem;
			line-height: 1.5;
			text-align: center;
			position:relative;
			margin-bottom: 60px;
			@include bpSp{
				font-size:2rem;
				margin-bottom: 30px;
			}
			&:before, &:after{
				content:'';
				display:block;
				position:absolute;
				height:1px;
				right:0;
				left:0;
				margin:0 auto;
				background-color:currentColor;
				//width:170px;
			}
			&:before{
				width:170px;
				bottom:-20px;
				@include bpSp{
					bottom:-10px;
				}
			}
			&:after{
				width:70px;
				bottom:-22px;
				@include bpSp{
					bottom:-12px;
				}
			}
		}

		p{
			font-size:1.8rem;
			@include bpSp{
				font-size:1.4rem;
				line-height: 1.5;
			}
		}
	}
}