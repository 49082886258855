@charset "UTF-8";

.btnStyle01{
	width:170px;
	height:50px;
	font-size:2.1rem;
	@include font(cz);
	line-height: 1;
	margin:0 auto;
	border:1px solid $color1;
	display:flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position:relative;
	transition:.2s ease;
	@include bpSp{
		max-width:100%;
		font-size:1.7rem;
		width:140px;
		height:35px;
	}
	&:before, &:after{
		content:'';
		display:block;
		position:absolute;
		width:70px;
		height:1px;
		background-color:$color1;
		top:-4px;
		left:0;
		right:0;
		margin:0 auto;
		transition:width .2s ease;
		@include bpSp{
			width:55px;
		}
	}
	&:after{
		top:auto;
		bottom:-4px;
	}
	&:hover{
		background-color:$color1;
		color:#fff;
		&:before, &:after{
			width:100%;
		}
	}
}

.c-pagination{
	@include font(cz);
	text-align: center;
	margin-top: 100px;
	@include bpSp{
		margin-top: 50px;
	}
	a{
		transition: 0.2s;
	}
	.page{
		color: #000;
		background-color: #fff;
		&:hover{
			background-color:$color1;
			color: #fff;
		}
	}
	.current{
		background-color: $color1;
		color: #fff;
	}
	.page, .current{
		border: 1px solid $color1;
		height:25px;
		width:25px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size:1.8rem;
		text-align: center;
		margin: 0 3px;
		@include bpSp{
			font-size:1.5rem;
			margin: 0 2px;
		}
	}
	.previouspostslink, .nextpostslink{
		position:relative;
	}
	.previouspostslink{
		padding-right:40px;
		@include bpSp{
			padding-right:20px;
		}
		&:after{
			content:'';
			position: absolute;
			background:url('../img/pagination.png') no-repeat;
			width:15px;
			height:17px;
			background-size:cover;
			right:20px;
			top:calc(50% - 9px);
			transform:scaleX(-1);
			@include bpSp{
				right:0;
			}
		}
	}
	.nextpostslink{
		padding-left:40px;
		@include bpSp{
			padding-left:20px;
		}
		&:after{
			content:'';
			position: absolute;
			background:url('../img/pagination.png') no-repeat;
			width:15px;
			height:17px;
			background-size:cover;
			left:20px;
			top:calc(50% - 9px);
			@include bpSp{
				left:0px;
			}
		}
	}
}
