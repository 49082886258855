#top{
	.top-slider-wr{
		position:relative;
		@include breakpoint(1030px){
			margin-bottom: 15px;
		}
		.top-slider{
			@keyframes slide {
				0% {
					transform: scale(1, 1);
				}
				100% {
					transform: scale(1.2, 1.2);
				}
			} 

			.moving {
				animation: slide 10s linear forwards;
			} 
			.slide-item{
				position:relative;
				overflow: hidden;
				&:before{
					content:'';
					position:absolute;
					border-style: solid;
					border-color: transparent transparent transparent #fff;
					border-width:6vw 0 0px 50vw;
					bottom:0;
					left:0;
					z-index: 200;
					@include bpSp{
						border-width:5.5vw 0px 0px 50vw;
					}
				}
				&:after{
					content:'';
					position:absolute;
					border-style: solid;
					border-color: transparent #fff transparent transparent;
					border-width:6vw 50vw 0px 0;
					bottom:0;
					right:0;
					z-index: 200;
					@include bpSp{
						border-width:5.5vw 50vw 0px 0;
					}
				} 			
				img{
					width:100%;
				}
			}
		}
		.top-slider-txt{
			position:absolute;
			font-size:1.68vw;
			letter-spacing: 0.7em;
			line-height: 1;
			@include center;
			color:#fff;
			font-weight: 300;
			width:100%;
			text-align: center;
			@include bpSp{
				font-size:5.15vw;
			}
		}
	}
	.top-introduce{
		background:$color1;
		padding:55px 0 70px;
		@include bpSp{
			padding:20px 0 35px;
		}
		.intro-logo{
			text-align: center;
			margin-bottom: 30px;
			@include bpSp{
				width:150px;
				margin:0 auto 15px;
			}
		}
		p{
			color:#fff;
			font-size:2rem;
			text-align: center;
			@include bpSp{
				font-size:1.4rem;
			}
		}
	}
	.top-report{
		padding:80px 0 0;
		@include bpSp{
			padding:40px 0 0;
		}
		.btnStyle01{
			@include bpSp{
				margin-top:20px;
			}
		}
		.aim{
			text-align: center;
			font-size:1.8rem;
			line-height: 1.5;
			margin-bottom: 40px;
			@include bpSp{
				font-size:1.4rem;
				margin-bottom: 20px;
			}
		}
	}

	.top-register{
		padding-top:80px;
		padding-bottom: 120px;
		@include bpSp{
			padding-top:40px;
			padding-bottom: 60px;
		}
		.btn-register{
			background:$color1;
			color:#fff;
			width:100%;
			display:block;
			text-align: center;
			padding:30px 0;
			border-radius: 20px;
			position: relative;
			border:2px solid transparent;
			@include bpSp{
				padding:15px 0;
			}
			&:before, &:after{
				content:'';
				display:block;
				position:absolute;
				left:0;
				right:0;
				margin:0 auto;
				height:2px;
				background:$color1;
			}
			&:before{
				bottom:-8px;
				width:83%;
			}
			&:after{
				bottom:-16px;
				width:33%;
			}
			&:hover{
				background:#fff;
				color:$color1;
				border-color: $color1;
			}
			.ja{
				font-size:3.6rem;
				line-height: 1;
				letter-spacing: 0.3em;
				position:relative;
				border-bottom: 1px solid currentColor;
				padding-bottom: 5px;
				@include bpSp{
					font-size:2rem;
					padding-bottom: 0px;
				}
				&:after{
					content:'';
					display:block;
					position:absolute;
					bottom:-5px;
					left:0;
					right:0;
					height:1px;
					width:70px;
					margin:0 auto;
					background-color:currentColor;
				}
			}
			.en{
				@include font(cz);
				display:block;
				margin-top:25px;
				line-height: 1;
				@include bpSp{
					font-size:1.3rem;
					margin-top:10px;
				}
			}
		}
	}
}