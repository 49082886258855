#activities{
	.page-ttl{
		background-image:url('../img/page-ttl/activities_ttl.jpg');
	}
	.list-activities{
		position:relative;
		.activity{
			display:flex;
			justify-content: space-between;
			margin-bottom: 20px;
			position:relative;
			@include bpSp{
				flex-direction: column!important;
			}
			&:before{
				content:'';
				position:absolute;
				height:20px;
				width:20px;
				background:$color1;
				top:-20px;
				left:0;
				right:0;
				margin:0 auto;
			}
			&:last-child{
				&:after{
					content:'';
					position:absolute;
					height:20px;
					width:20px;
					background:$color1;
					bottom:-20px;
					left:0;
					right:0;
					margin:0 auto;
				}
			}
			&:first-child{
				margin-top:20px;
			}
			& > div{
				width:calc(50% - 10px);
				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
					width:50%;
				}
				@include bpSp{
					width:100%;
				}
			}
			&.reverse{
				flex-direction: row-reverse;
				.content{
					.inner{
						margin-left:auto;
						@include bpSp{
							margin-left: 0;
						}
					}
					.activity-ttl{
						text-align: right;
						@include bpSp{
							text-align: left;
						}
						&:after{
							left:auto;
							right:0;
							@include bpSp{
								left:0;
								right:auto;
							}
						}
					}
				}

				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
					.bg{
						margin-right:0px;
						margin-left:10px;
						@include bpSp{
							margin-left:0px;
						}
					}
					.content{
						margin-left:0px;
						margin-right:10px;
						@include bpSp{
							margin-right:0px;
						}
					}
				}
			}

			//fixed IE
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
				.bg{
					margin-left:0px;
					margin-right:10px;
					@include bpSp{
						margin-right:0px;
					}
				}
				.content{
					margin-right:0px;
					margin-left:10px;
					@include bpSp{
						margin-left:0px;
					}
				}
			}
			.bg{
				background-size:cover;
				background-repeat: no-repeat;
				background-position: center center;
				@include bpSp{
					padding-bottom: 50%;
				}
				&.bg01{
					background-image:url('../img/activity_01.jpg');
				}				
				&.bg02{
					background-image:url('../img/activity_02.jpg');
				}				
				&.bg03{
					background-image:url('../img/activity_03.jpg');
				}				
				&.bg04{
					background-image:url('../img/activity_04.jpg');
				}				
				&.bg05{
					background-image:url('../img/activity_05.jpg');
				}				
				&.bg06{
					background-image:url('../img/activity_06.jpg');
				}
			}
			.content{
				background:#DADFDC;
				min-height: 360px;
				display:flex;
				align-items: center;
				padding:20px 3.25%;
				@include bpSp{
					min-height:100%;
					padding: 25px 15px;
				}
				.inner{
					width:360px;
					@include bpSp{
						max-width:100%;
						margin:0 auto!important;
					}
				}
				.activity-ttl{
					position:relative;
					margin-bottom: 20px;
					&:before, &:after{
						content:'';
						display:block;
						position:absolute;
						height:1px;
						background-color:currentColor;
						left:0;
					}

					&:before{
						width:100%;
						bottom:-8px;
						@include bpSp{
							//width:50%;
						}
					}
					&:after{
						width:40%;
						bottom:-10px;
						@include bpSp{
							//width:20%;
						}
					}
					.en{
						@include font(cz);
						line-height: 1;
						font-size:1.6rem;
						@include bpSp{
							font-size:1.3rem;
						}
					}
					.ja{
						font-size:3.6rem;
						line-height: 1.5;
						letter-spacing: 0.5em;
						@include breakpoint(1030px){
							font-size:3rem;
						}
						@include bpSp{
							font-size:2.2rem;
						}
						&.fit{
							letter-spacing: 0;
						}
					}
				}
				p{
					font-size:1.8rem;
					letter-spacing: 0.05em;
					@include bpSp{
						font-size:1.4rem;
						line-height: 1.5;
					}
				}
			}
		}
	}
}