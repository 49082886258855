#mission{
	.page-ttl{
		background-image:url('../img/page-ttl/mission_ttl.jpg');
	}

	.mission-content{
		margin-top: -50px;
		@include bpSp{
			margin-top:-25px;
		}
		p{
			font-size:1.9rem;
			@include bpSp{
				font-size:1.5rem;
			}
		}
		.image{
			text-align: center;
			&.tree{
				margin-bottom: -70px;
				@include bpSp{
					width:38.9vw;
					margin:0 auto -35px;
				}
			}
			&.leaf{
				margin: 70px 0 50px;
				@include bpSp{
					margin:25px 0;
				}
			}
		}
		.quote{
			display:block;
			font-size:3.1rem;
			line-height: 1;
			text-align:center;
			padding:70px 0;
			@include bpSp{
				font-size:2rem;
				padding:35px 0;
				line-height: 1.5;
			}
		}
		.ttlStyle01{
			margin-bottom: 20px;
		}
		.mission-green{
			margin-top:130px;	
			width:100%;
			@include bpTb{
				background:url('../img/mission_green.jpg') no-repeat;
				background-size:cover;
				background-position:center center;
				padding-bottom: 45%;
				margin-top:60px;
				img{
					display:none;
				}
			}
			@include bpSp{
				margin-top:30px;
			}
		}
	}
}