@charset "UTF-8";

/*******************************
Style Start
*******************************/
.tb-only {
  display: none;
  @include bpTb {
    display: inline-block;
  }
}

.sp-only {
  display: none;
  @include bpSp {
    display: inline-block;
  }
}

.pc-only {
  display: inline-block;
  @include bpTb {
    display: none;
  }
}

.displayNone{
  display:none;
}
