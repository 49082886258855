@charset "UTF-8";

.ttlStyle01{
	text-align: center;
	margin-bottom: 40px;
	@include bpSp{
		margin-bottom: 20px;
	}
	.en{
		@include font(cz);
		font-size:5rem;
		line-height: 1;
		display:inline-block;
		//border-bottom:1px solid currentColor;
		position:relative;
		margin-bottom: 10px;
		@include bpSp{
			font-size:4rem;
		}
		&:before{
			content:'';
			position:absolute;
			bottom:0px;
			background-color:currentColor;
			height:1px;
			width:175px;
			left:0;
			right:0;
			margin:0 auto;
			@include bpSp{
				width:150px;
			}
		}
		&:after{
			content:'';
			position:absolute;
			bottom:-5px;
			background-color:currentColor;
			width:70px;
			height:1px;
			left:0;
			right:0;
			margin:0 auto;
			@include bpSp{
				width:50px;
			}
		}
	}
	.ja{
		line-height: 1.5;
		@include bpSp{
			font-size:1.3rem;
		}
	}
	&.white{
		color:#fff;
	}
	&.bigger{
		.en{
			font-size:9.3rem;
			letter-spacing: 0.2em;
			&:before{
				width:330px;
				@include bpSp{
					width:190px;
				}
			}
			@include bpSp{
				font-size:5rem;
				letter-spacing: 0.1em;
			}
		}
	}
}

.page-ttl{
	background-size:cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	height:400px;
	width:100%;
	@include breakpoint(1030px){
		margin-bottom: 15px;
	}
	@include bpSp{
		height:250px;
	}
}